/**
 *  项目的配置文件
 */
export const apiUrl = '/';//接口请求地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const chatUrl = 'wss://im.teyounongpin.com';//客服地址
export const mUrl = 'https://m.teyounongpin.com/'//移动端网页地址
export const title = '特优农品';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

// ** copyright *** slodon *** version-v3.7 *** date-2022-02-18 ***主版本v3.7



